interface BankStore {
  bankAccountList: Array<any>;
  bankList: Array<any>;
  BankChooseLink: Record<string, unknown>;
}

export const name = 'bank';
export default {
  namespaced: true,
  state: () =>
    ({
      bankAccountList: [],
      bankList: [],
      BankChooseLink: {},
    } as BankStore),
  mutations: {
    setBankList(state: BankStore, data: Array<any>) {
      state.bankList = data;
    },
    setBankAccountList(state: BankStore, data: Array<any>) {
      state.bankAccountList = data;
    },
    setBankChooseLink(state: BankStore, data: Record<string, unknown>) {
      state.BankChooseLink = data;
    },
  },
  getters: {
    getBankList(store: BankStore) {
      return store.bankList;
    },
    getBankAccountList(store: BankStore) {
      return store.bankAccountList;
    },
    getBankChooseLink(store: BankStore) {
      return store.BankChooseLink;
    },
  },
};
