import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: ConstRouter.INVESTMENT_CONFIRM.path,
    name: ConstRouter.INVESTMENT_CONFIRM.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./InvestmentConfirm.vue'),
  },
  {
    path: ConstRouter.INVESTMENT_SUCCESS.path,
    name: ConstRouter.INVESTMENT_SUCCESS.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./InvestmentResult.vue'),
  },
  {
    path: ConstRouter.INVESTMENT_INPUT_MOENY.path,
    name: ConstRouter.INVESTMENT_INPUT_MOENY.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./InvestmentInputMoney.vue'),
  },
];
