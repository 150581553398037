import ConstRouter from '@/constants/constRouter';
// import { requireName } from '@/middleware';
import { checkNotLogin } from '@/middleware';

export default [
  {
    path: ConstRouter.INTRO.path,
    name: ConstRouter.INTRO.name,
    meta: {
      middleware: [checkNotLogin],
    },
    component: () => import('./IntroSlide.vue'),
  },
  // {
  //   path: ConstRouter.INTRO_ACCOUNT.path,
  //   name: ConstRouter.INTRO_ACCOUNT.name,
  //   component: () => import('./IntroHadAccount.vue'),
  // },
  // {
  //   path: ConstRouter.INTRO_INPUT_NAME.path,
  //   name: ConstRouter.INTRO_INPUT_NAME.name,
  //   component: () => import('./IntroInputName.vue'),
  // },
  // {
  //   path: ConstRouter.INTRO_CHOOSE_NEED.path,
  //   name: ConstRouter.INTRO_CHOOSE_NEED.name,
  //   meta: {
  //     middleware: [requireName],
  //   },
  //   component: () => import('./IntroChooseNeed.vue'),
  // },
];
