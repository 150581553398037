import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: ConstRouter.QA.path,
    name: ConstRouter.QA.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./Q&A.vue'),
  },
];
