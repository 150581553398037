import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: ConstRouter.INVEST_DETAIL.path,
    name: ConstRouter.INVEST_DETAIL.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./InvestDetail.vue'),
  },
];
