import ConstRouter from '@/constants/constRouter';
import { getAuthToken } from '@/helpers/localStorage';

export function auth() {
  if (!getAuthToken()) {
    return ConstRouter.REGISTER_PHONE.name;
  }
  return null;
}

export function checkNotLogin() {
  if (getAuthToken()) {
    return ConstRouter.SURVEY_TEST.name;
  }
  return null;
}
