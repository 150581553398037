import Axios from '../services/axios';

const PATH = 'lender/customers';

export function listBankAccount() {
  return Axios.get(`${PATH}/bank-accounts`);
}

export function overViewInvestment() {
  return Axios.get(`${PATH}/overview`);
}
