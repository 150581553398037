export function isEmptyObj(obj: any): boolean {
  return Object.keys(obj).length == 0;
}

export function removeEmptyFields(obj: any): any {
  const temp = { ...obj };
  Object.keys(temp).forEach((key) => {
    if (temp[key] === undefined || temp[key] === null || temp[key] === '') {
      delete temp[key];
    }
  });
  return temp;
}
