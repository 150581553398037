import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: ConstRouter.MARKET_PLACE.path,
    name: ConstRouter.MARKET_PLACE.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./MarketPlace.vue'),
  },
];
