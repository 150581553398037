import Axios from '../services/axios';

const PATH = 'lender/auth';
export function registerPhone(phone: string) {
  return Axios.post(`${PATH}/login-register`, {
    phoneNumber: phone,
  });
}

export function verifyOTP(data: any) {
  return Axios.post(`${PATH}/verify`, data);
}

export function refreshToken() {
  return Axios.post(`${PATH}/refresh`);
}

export function profile() {
  return Axios.get(`${PATH}/profile`);
}
