import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import constRouter from '@/constants/constRouter';

// General route
let routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: constRouter.INTRO.path,
  },
];

// Auto-load all router.ts in folder views
const requireComponent = require.context('../views', true, /router.ts$/);
requireComponent.keys().forEach((fileName: string) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  routes = routes.concat(componentConfig.default || componentConfig);
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function hasQueryParams(route: any) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  let toWithQuery;

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    toWithQuery = Object.assign({}, to, { query: from.query });
  }

  if (to.meta && to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    // Get next name route
    const nextNameRoute = middleware[0]();

    // Navigate
    if (nextNameRoute) {
      next({ name: nextNameRoute, query: from.query });
    } else {
      toWithQuery ? next(toWithQuery) : next();
    }
  } else {
    toWithQuery ? next(toWithQuery) : next();
  }
});

export default router;
