interface AuthStore {
  phone: string;
}
export const name = 'register';
export default {
  state: () =>
    ({
      phone: '',
    } as AuthStore),
  mutations: {
    setPhone(state: AuthStore, data: string) {
      // `state` is the local module state
      state.phone = data;
    },
  },
  getters: {
    getPhone(state: AuthStore) {
      return state.phone;
    },
  },
};
