export const name = 'investment';
interface InvestmentStore {
  transferInfo: Record<any, unknown>;
  bankTransfer: Record<any, unknown>;
  investmentInfo: Record<any, unknown>;
}
export default {
  state: () =>
    ({
      transferInfo: {},
      bankTransfer: {},
      investmentInfo: {},
    } as InvestmentStore),
  mutations: {
    setTransferInfo(state: InvestmentStore, data: Record<any, unknown>) {
      state.transferInfo = data;
    },
    setBankTransfer(state: InvestmentStore, data: Record<any, unknown>) {
      state.bankTransfer = data;
    },
    setInvestmentInfo(state: InvestmentStore, data: Record<any, unknown>) {
      state.investmentInfo = data;
    },
  },
  getters: {
    getTransferInfo(state: InvestmentStore) {
      return state.transferInfo;
    },
    getBankInfo(state: InvestmentStore) {
      return state.bankTransfer;
    },
    getInvestmentInfo(state: InvestmentStore) {
      return state.investmentInfo;
    },
  },
};
