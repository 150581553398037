import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: ConstRouter.BANK_ACCOUNT.path,
    name: ConstRouter.BANK_ACCOUNT.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./BankListAccount.vue'),
  },
  {
    path: ConstRouter.BANK_LIST.path,
    name: ConstRouter.BANK_LIST.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./BankList.vue'),
  },
  {
    path: ConstRouter.BANK_ACCOUNT_INFO.path,
    name: ConstRouter.BANK_ACCOUNT_INFO.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./BankAccountInfo.vue'),
  },
];
