export const name = 'investDetail';
interface InvestDetailStore {
  contractInfo: Record<any, unknown>;
}
export default {
  state: () =>
    ({
      contractInfo: {},
    } as InvestDetailStore),
  mutations: {
    setConTractInfo(state: InvestDetailStore, data: Record<any, unknown>) {
      state.contractInfo = data;
    },
  },
  getters: {
    getContractInfo(state: InvestDetailStore) {
      return state.contractInfo;
    },
  },
};
