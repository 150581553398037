// TOKEN
export function destroyToken() {
  localStorage.removeItem('accessToken');
}

export function destroyRefreshToken() {
  localStorage.removeItem('refreshToken');
}

export function setAuthToken(token: string) {
  localStorage.setItem('accessToken', token);
}

export function setRefreshToken(token: string) {
  localStorage.setItem('refreshToken', token);
}

export function getAuthToken() {
  if (localStorage.accessToken) {
    return localStorage.accessToken;
  }
  return null;
}

export function getRefreshToken() {
  if (localStorage.refreshToken) {
    return localStorage.refreshToken;
  }
  return null;
}

// contract is approve
export function setApproval(data: string) {
  localStorage.setItem('isApprove', data);
}

export function getApproval() {
  if (localStorage.isApprove) {
    return localStorage.isApprove;
  }
  return null;
}

// set contract id
export function setContractId(data: string) {
  localStorage.setItem('contractId', data);
}

export function getContractId() {
  if (localStorage.contractId) {
    return localStorage.contractId;
  }
  return null;
}

export function setSurvey(surveyId: string, answer: string) {
  localStorage.setItem('survey', JSON.stringify({ surveyId, answer }));
}

export function getSurvey() {
  if (localStorage.survey) {
    return localStorage.survey;
  }
  return null;
}

export function setIsFistShowIntroModal(status: boolean) {
  localStorage.setItem('showedIntroModal', JSON.stringify(status));
}

export function getIsFistShowIntroModal() {
  if (localStorage.showedIntroModal) {
    return localStorage.showedIntroModal;
  }
  return null;
}
