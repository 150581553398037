import ConstRouter from '@/constants/constRouter';
import { checkNotLogin } from '@/middleware';

export default [
  {
    path: ConstRouter.REGISTER_PHONE.path,
    name: ConstRouter.REGISTER_PHONE.name,
    meta: {
      middleware: [checkNotLogin],
    },
    component: () => import('./RegisterPhone.vue'),
  },
  {
    path: ConstRouter.CONFIRM_OTP.path,
    name: ConstRouter.CONFIRM_OTP.name,
    meta: {
      middleware: [checkNotLogin],
    },
    component: () => import('./ConfirmOTP.vue'),
  },
];
