export const name = 'marketPlace';
import { listContract } from '@/apis/ApiContract';
interface MarketPlaceStore {
  listContract: Array<any>;
  currentContract: Array<any>;
  page: number;
}
export default {
  state: () =>
    ({
      listContract: [],
      page: 1,
      currentContract: [],
    } as MarketPlaceStore),
  mutations: {
    SET_LIST_CONTRACT(state: MarketPlaceStore, data: Array<any>) {
      state.listContract = JSON.parse(JSON.stringify(data));
    },
    SET_PAGE(state: MarketPlaceStore, page: number) {
      state.page = page;
    },
    SET_CURRENT_CONTRACT(state: MarketPlaceStore, data: Array<any>) {
      state.currentContract = data;
    },
  },
  actions: {
    async fetchListContract({
      commit,
      state,
    }: {
      commit: any;
      state: MarketPlaceStore;
    }) {
      try {
        const res = await listContract(state.page);
        const newData = state.listContract;
        newData.push(...res?.data);
        commit('SET_PAGE', state.page + 1);
        commit('SET_LIST_CONTRACT', newData);
        commit('SET_CURRENT_CONTRACT', res.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },
  },
  getters: {
    getListContract(state: MarketPlaceStore) {
      return state.listContract;
    },
    getPage(state: MarketPlaceStore) {
      return state.page;
    },
    getCurrentContract(state: MarketPlaceStore) {
      return state.currentContract;
    },
  },
};
