import Axios from '../services/axios';

const PATH = 'lender/investments';

export function historyTransaction() {
  return Axios.get(`${PATH}/transactions`);
}

export function historyInvestment(page = 1, limit = 10) {
  return Axios.get(`${PATH}?page=${page}&limit=${limit}`);
}

export function overviewPool(id: string) {
  return Axios.get(`${PATH}/pool-contracts/${id}`);
}

export function overviewChart(time: number) {
  return Axios.get(`${PATH}/chart-info/${time}`);
}

export function listPoolInvest(page = 1, limit = 10) {
  return Axios.get(`${PATH}/pool-contracts?page=${page}&limit=${limit}`);
}
