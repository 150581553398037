interface DashboardStore {
  poolList: Array<any>;
}
export const name = 'dashboard';

export default {
  state: () =>
    ({
      poolList: [],
    } as DashboardStore),
  mutations: {
    setPoolList(state: DashboardStore, data: Array<any>) {
      // `state` is the local module state
      state.poolList = data;
    },
  },
  getters: {
    getPoolList(state: DashboardStore) {
      return state.poolList;
    },
  },
};
