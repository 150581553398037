import forEach from 'lodash/forEach';

export default {
  methods: {
    __(key: any, replace?: any) {
      let translation = (window as any).translations[key]
        ? (window as any).translations[key]
        : key;

      forEach(replace, (value, key2) => {
        const searches = [
          `:${key2}`,
          `:${key2.toUpperCase()}`,
          `:${key2.charAt(0).toUpperCase()}${key2.slice(1)}`,
        ];

        const replacements = [
          value,
          value.toUpperCase(),
          value.charAt(0).toUpperCase() + value.slice(1),
        ];

        for (let i = searches.length - 1; i >= 0; i -= 1) {
          translation = translation.replace(searches[i], replacements[i]);
        }
      });
      return translation;
    },
  },
};
