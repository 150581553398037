export default {
  //=======================INTRO=======================
  INTRO: {
    path: '/intro',
    name: 'intro',
  },

  //=======================SURVEY_TEST=======================
  SURVEY_TEST: {
    path: '/survey',
    name: 'survey',
  },

  //=======================DASHBOARD=======================
  DASHBOARD: {
    path: '/dashboard',
    name: 'dashboard',
  },
  //=======================REQUIRE_MOBILE=======================
  REQUIRE_MOBILE: {
    path: '/require-mobile',
    name: 'require.mobile',
  },

  //=======================REGISTER=======================
  REGISTER_PHONE: {
    path: '/phone',
    name: 'phone',
  },
  CONFIRM_OTP: {
    path: '/otp',
    name: 'otp',
  },

  //=======================EKYC=======================
  EKYC: {
    path: '/ekyc',
    name: 'ekyc',
  },
  EKYC_VIEW: {
    path: '/ekyc/view',
    name: 'ekyc.view',
  },
  EKYC_INTRO: {
    path: '/ekyc/intro',
    name: 'ekyc.intro',
  },
  EKYC_INTRO_CONFIRM: {
    path: '/ekyc/confirm',
    name: 'ekyc.confirm',
  },
  //=========================market-place====================
  MARKET_PLACE: {
    path: '/market-place',
    name: 'marketplace',
  },
  INVEST_DETAIL: {
    path: '/invest-detail/:id',
    name: 'investdetail',
  },
  //=========================investment====================
  INVESTMENT_CONFIRM: {
    path: '/investment/confirm',
    name: 'investment.confirm',
  },
  INVESTMENT_SUCCESS: {
    path: '/investment/result',
    name: 'investment.result',
  },
  INVESTMENT_INPUT_MOENY: {
    path: '/investment/input-money',
    name: 'investment.inputmoney',
  },
  //=========================payment====================
  CHOOSE_METHOD_PAYMENT: {
    path: '/payment/method',
    name: 'payment.method',
  },
  LIST_ACCOUNT_BANK: {
    path: '/payment/account',
    name: 'payment.account',
  },

  //=========================profile====================
  PROFILE: {
    path: '/profile',
    name: 'profile',
  },

  //=========================overview====================
  OVERVIEW: {
    path: '/overview',
    name: 'overview',
  },
  OVERVIEW_HISTORY_INVESTMENT: {
    path: '/investment-history',
    name: 'investment.history',
  },
  OVERVIEW_HISTORY_TRANSACTION: {
    path: '/transaction-history/:id',
    name: 'transaction.history',
  },
  OVERVIEW_PACEKAGE: {
    path: '/overview-package/:id',
    name: 'overview.package',
  },
  OVERVIEW_WITHDRAWMONEY: {
    path: '/withdraw-money/:id',
    name: 'withdraw.money',
  },
  //=========================bank====================
  BANK_ACCOUNT: {
    path: '/bank-account',
    name: 'bank.account',
  },
  BANK_LIST: {
    path: '/bank-list',
    name: 'bank.list',
  },
  BANK_ACCOUNT_INFO: {
    path: '/bank-account-info',
    name: 'bank.account.info',
  },
  //=========================404====================
  NOT_FOUND: {
    path: '/404',
    name: '404',
  },
  //=========================INVESTMENT_PACKAGE====================
  INVESTMENT_PACKAGE: {
    path: '/investment-packages',
    name: 'investment.packages',
  },
  INVESTMENT_PACKAGE_DETAIL: {
    path: '/investment-packages/:id',
    name: 'investment-packages.detail',
  },
  //=========================Q&A====================
  QA: {
    path: '/fags',
    name: 'Q&A',
  },
};
