import axios from 'axios';
import {
  destroyToken,
  destroyRefreshToken,
  getAuthToken,
  getRefreshToken,
  setAuthToken,
} from '@/helpers/localStorage';
import constRouter from '@/constants/constRouter';
import { refreshToken } from '@/apis/ApiAuth';
const Axios = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
  withCredentials: false,
});

Axios.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers.common['Authorization'] = token;
  }
  return config;
});

// For Refreshing Token
let isAlreadyFetchingAccessToken = false;

Axios.interceptors.response.use(
  (response) => Promise.resolve(response.data),
  (error) => {
    const { config, response } = error;
    const originalRequest = config;
    if (response?.status) {
      switch (+response.status) {
        case 400:
          // do something
          break;
        case 401: {
          const token = getRefreshToken();
          setAuthToken(token);
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            refreshToken()
              .then((res) => {
                isAlreadyFetchingAccessToken = false;
                if (res.data?.accessToken) {
                  setAuthToken(res.data?.accessToken);
                  onAccessTokenFetched(res.data?.accessToken);
                } else {
                  destroyToken();
                  destroyRefreshToken();
                  document.location.href = constRouter.REGISTER_PHONE.path;
                }
              })
              .catch((err) => {
                console.log(err);
                destroyToken();
                destroyRefreshToken();
                document.location.href = constRouter.REGISTER_PHONE.path;
              });
          } else {
            // 401 of refresh token
            const originalRequest = error.config;
            if (originalRequest.url.includes('refresh')) {
              isAlreadyFetchingAccessToken = false;
              destroyToken();
              destroyRefreshToken();
              document.location.href = constRouter.REGISTER_PHONE.path;
            }
          }
          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((accessToken: any) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${accessToken}`;
              resolve(Axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        case 403: {
          destroyToken();
          document.location.href = constRouter.REGISTER_PHONE.path;
          break;
        }
        case 404:
          // Vue.$router.push({ name: "404" });
          break;
        case 419: {
          // 401 of refresh token
          destroyToken();
          destroyRefreshToken();
          document.location.href = constRouter.REGISTER_PHONE.path;
          break;
        }
        case 502:
          setTimeout(() => {
            // Vue.$router.replace({
            //   path: "/login",
            //   query: {
            //     redirect: router.currentRoute.fullPath
            //   }
            // });
          }, 1000);
          break;
        default:
          break;
      }
    }
    return Promise.reject(error.response);
  },
);
let subscribers: any[] = [];
const onAccessTokenFetched = (accessToken: any) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback: any) => {
  subscribers.push(callback);
};

export default Axios;
