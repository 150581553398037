import { createStore } from 'vuex';
import { profile } from '@/apis/ApiAuth';
import { overViewInvestment } from '@/apis/ApiCustomer';
import { overviewChart } from '@/apis/ApiPoolInvestment';
const modules: any = {};

declare global {
  interface Window {
    filumanalytics: any;
  }
}

// Auto-load all store.ts in folder views
const requireComponent = require.context('../views', true, /store.ts$/);
requireComponent.keys().forEach((fileName: string) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  modules[componentConfig.name] = componentConfig.default;
});

const getDefaultState = () => {
  return {
    user: {},
    loading: false,
    campaign: {
      utmSource: undefined,
      utmContent: undefined,
      clickId: undefined,
      utmCampaign: undefined,
    },
  };
};

const store = createStore({
  state: getDefaultState(),
  mutations: {
    SET_USER_INFO(state, data) {
      state.user = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_CAMPAIGN(state, data) {
      state.campaign = data;
    },
  },
  actions: {
    async fetchUserInfo({ commit }) {
      commit('SET_LOADING', true);
      try {
        const resp = await profile();
        commit('SET_USER_INFO', resp.data);
        commit('SET_LOADING', false);
        window.filumanalytics &&
          window.filumanalytics.identify(resp.data.id, {});
      } catch (err) {
        // Handle Error Here
        console.error(err);
        commit('SET_LOADING', false);
      }
    },
    async fetchOverview({ commit }) {
      try {
        const res1 = await overViewInvestment();
        commit('setOverview', res1.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },
    async fetchOverviewChart({ commit }, arg = 1) {
      try {
        const res2 = await overviewChart(arg);
        commit('setDataChart', res2.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getUser(state) {
      return state.user;
    },
    getCampaign(state) {
      return state.campaign;
    },
  },
  modules,
});

export default store;
