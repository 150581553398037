import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  // {
  //   path: ConstRouter.OVERVIEW.path,
  //   name: ConstRouter.OVERVIEW.name,
  //   meta: {
  //     middleware: [auth],
  //   },
  //   component: () => import('./Overview.vue'),
  // },
  {
    path: ConstRouter.OVERVIEW_HISTORY_INVESTMENT.path,
    name: ConstRouter.OVERVIEW_HISTORY_INVESTMENT.name,
    meta: {
      middleware: [auth],
    },
    component: () =>
      import(
        './OverviewHistoryInvestmentPool/OverviewHistoryInvestmentPool.vue'
      ),
    // component: () => import('./OverviewHistoryInvestment.vue'),
  },
  {
    path: ConstRouter.OVERVIEW_HISTORY_TRANSACTION.path,
    name: ConstRouter.OVERVIEW_HISTORY_TRANSACTION.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./OverviewHistoryTransaction.vue'),
  },
  {
    path: ConstRouter.OVERVIEW_PACEKAGE.path,
    name: ConstRouter.OVERVIEW_PACEKAGE.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./OverviewPackage.vue'),
  },
  {
    path: ConstRouter.OVERVIEW_WITHDRAWMONEY.path,
    name: ConstRouter.OVERVIEW_WITHDRAWMONEY.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./OverviewWithDrawMoney.vue'),
  },
];
