import Axios from '../services/axios';
import axios from 'axios';

const PATH = 'lender/contracts';

export function listContract(page = 1, limit = 10) {
  return Axios.get(`${PATH}?page=${page}&limit=${limit}`);
}

export function detailContract(contractId: string) {
  return Axios.get(`${PATH}/${contractId}`);
}

export function listInvestmentPackage(page = 1, limit = 10) {
  return axios.get('../../fakeData/contractPackage.json');
}

export async function detailInvestmentPackage(id: string | string[]) {
  const res = await axios.get('../../fakeData/contractPackage.json');
  const data = res.data?.data;
  let item: any = null;
  if (data) {
    item = data.find((item: any) => item.id == id);
  }

  return new Promise((resolve, reject) => {
    if (item) {
      resolve({
        data: { data: item },
      });
    } else {
      reject(new Error('404 not found'));
    }
  });
}
