export const name = 'investmentPackage';
interface InvesetmentPackageListStore {
  listPackage: Array<any>;
  packageChoosed: Record<any, unknown>;
}
export default {
  state: () =>
    ({
      listPackage: [],
      packageChoosed: {},
    } as InvesetmentPackageListStore),
  mutations: {
    setPackage(state: InvesetmentPackageListStore, data: Record<any, unknown>) {
      state.packageChoosed = data;
    },
  },
  actions: {},
  getters: {
    getListInvestmentPackage(state: InvesetmentPackageListStore) {
      return state.listPackage;
    },
    getPackage(state: InvesetmentPackageListStore) {
      return state.packageChoosed;
    },
  },
};
