import ConstRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: ConstRouter.INVESTMENT_PACKAGE.path,
    name: ConstRouter.INVESTMENT_PACKAGE.name,
    meta: {
      middleware: [auth],
    },
    component: () => import('./InvestmentPackageList.vue'),
  },
  {
    path: ConstRouter.INVESTMENT_PACKAGE_DETAIL.path,
    name: ConstRouter.INVESTMENT_PACKAGE_DETAIL.name,
    meta: {
      middleware: [auth],
    },
    component: () =>
      import('./InvestmentPackageDetail/InvestmentPackageDetail.vue'),
  },
];
