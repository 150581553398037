import { listPoolInvest } from '@/apis/ApiPoolInvestment';
export const name = 'overview';
interface OverviewStore {
  overviewPool: Record<any, unknown>;
  dataChart: any;
  historyTransaction: Record<any, unknown>;
  historyInvestment: Array<any>;
  currentHistoryInvestment: Array<any>;
  page: number;
}
export default {
  state: () =>
    ({
      overviewPool: {},
      dataChart: {},
      historyTransaction: {},
      historyInvestment: [],
      currentHistoryInvestment: [],
      page: 1,
    } as OverviewStore),
  actions: {
    async fetchListHistoryInvest({
      commit,
      state,
    }: {
      commit: any;
      state: OverviewStore;
    }) {
      try {
        const res = await listPoolInvest(state.page);
        const newData = state.historyInvestment;
        newData.push(...res.data);
        commit('SET_INVESETMENT_PAGE', state.page + 1);
        commit('SET_HISTORY_INVESETMENT', newData);
        commit('SET_CURRENT_HISTORY_INVESTMENT', res.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },
  },
  mutations: {
    setOverview(state: OverviewStore, data: Record<any, unknown>) {
      state.overviewPool = data;
    },
    setDataChart(state: OverviewStore, data: any) {
      state.dataChart = data;
    },
    setHistoryTransaction(state: OverviewStore, data: any) {
      state.historyTransaction = data;
    },
    SET_HISTORY_INVESETMENT(state: OverviewStore, data: Array<any>) {
      state.historyInvestment = JSON.parse(JSON.stringify(data));
    },
    SET_INVESETMENT_PAGE(state: OverviewStore, page: number) {
      state.page = page;
    },
    SET_CURRENT_HISTORY_INVESTMENT(state: OverviewStore, data: Array<any>) {
      state.currentHistoryInvestment = data;
    },
  },
  getters: {
    getOverviewPool(state: OverviewStore) {
      return state.overviewPool;
    },
    getDataChart(state: OverviewStore) {
      return state.dataChart;
    },
    getHistoryTransaction(state: OverviewStore) {
      return state.historyTransaction;
    },
    getHistoryInvestment(state: OverviewStore) {
      return state.historyInvestment;
    },
    getHistoryInvestPage(state: OverviewStore) {
      return state.page;
    },
    getCurrentHistoryInvest(state: OverviewStore) {
      return state.currentHistoryInvestment;
    },
  },
};
