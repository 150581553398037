
import { defineComponent } from 'vue';
import { mapActions, mapMutations } from 'vuex';
import { getAuthToken } from '@/helpers/localStorage';
import { isEmptyObj, removeEmptyFields } from '@/helpers/object';

export default defineComponent({
  name: 'App',
  // watch: {
  //   $route() {
  //     if (process.env.NODE_ENV === 'production') {
  //       // eslint-disable-next-line
  //       // @ts-ignore TS7015
  //       // sematext experience
  //       window['strum']('routeChange', document.location.href);
  //     }
  //   },
  // },
  created() {
    if (getAuthToken()) {
      this.fetchUserInfo();
      // this.fetchOverview();
      // this.fetchOverviewChart();
    }
    this.getCampaignFromUrl();
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
      fetchOverview: 'fetchOverview',
      fetchOverviewChart: 'fetchOverviewChart',
    }),
    ...mapMutations({
      setCampaign: 'SET_CAMPAIGN',
    }),
    getCampaignFromUrl() {
      const url = new URL(window.location.href);
      const utmSource = url.searchParams.get('utm_source');
      const utmContent = url.searchParams.get('utm_content');
      const clickId = url.searchParams.get('click_id');
      const utmCampaign = url.searchParams.get('utm_campaign');

      let campaign = {
        utmSource,
        utmContent,
        clickId,
        utmCampaign,
      };
      campaign = removeEmptyFields(campaign);

      // store to state
      if (!isEmptyObj(campaign)) {
        this.setCampaign(campaign);
      }
    },
  },
});
